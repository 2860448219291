const trackGoal = (goalId) => {
    if (!goalId || typeof goalId !== 'number') {
        console.error("Goal ID deve ser um número válido.");
        return;
    }

    window._paq = window._paq || [];
    window._paq.push(['trackGoal', goalId]);
};

export default trackGoal;