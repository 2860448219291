import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../routes"
import axios from '../services/axios'
import Swal from 'sweetalert2'
import ReCAPTCHA from "react-google-recaptcha"
import Loading from './Loading';
import matchTelefone from "../functions/matchTelefone";
import encrypt from "../functions/Encrypt";

const LoginModal = ({ closeModal }) => {
  const navigate = useNavigate();
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [telefone, setTelefone] = useState("");
  const [senha, setSenha] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [showTelefone, setShowTelefone] = useState(false);
  const [showSenha, setShowSenha] = useState(false);
  const [dadosRequest, setDadosRequest] = useState(null)


  const somenteNumeros = (valor) => {
    return valor.replace(/\D/g, ''); 
  };

  const handleChangeCpfCnpj = (event) => {
    const valorSemMascara = somenteNumeros(event.target.value);
    setCpfCnpj(valorSemMascara);
  };

  const handleChangeTelefone = (event) => {
    setTelefone(event.target.value);
  };

  const handleChangeSenha = (event) => {
    setSenha(event.target.value);
  };

  const handleConfirm = async () => {
    if (!showTelefone && !showSenha) {
      if (!cpfCnpj || cpfCnpj == "") {
        return Swal.fire({
          icon: "error",
          title: "Atenção",
          text: "Preencha corretamente o campo de CPF/CNPJ para efetuar o login",
        });
      }

      // if (!recaptchaValue) {
      //   return Swal.fire({
      //     icon: "error",
      //     title: "Atenção",
      //     text: "Por favor, complete o reCAPTCHA.",
      //   });
      // }
      try {
        setIsLoading(true)
        const encriptado = await encrypt(cpfCnpj)
        const request = await axios.get(routes.authentication + encriptado)
        if (!request.data) {
          return Swal.fire({
            icon: "info",
            title: "Nenhum registro localizado!",
            text: "Não foi possivel localizar nenhum associado ou associação com esse CPF/CNPJ. Valide os dados informados",
          });
        } else {
          setDadosRequest(request.data)
          if (request.data.cpf){
            const encriptado = await encrypt(cpfCnpj)
            await navigate(`/nps/${encriptado}`);
            return
            // setShowTelefone(true)
          }else
            setShowSenha(true)

          return
        }
      } catch (err) {

      } finally {
        setIsLoading(false)
      }
    } else {
      if (!showSenha) {
        // if (!telefone || telefone.length < 4) {
        //   return Swal.fire({
        //     icon: "error",
        //     title: "Atenção",
        //     text: "Preencha corretamente o campo de telefone/celular para efetuar o login.",
        //   });
        // }
        // if (matchTelefone(dadosRequest.telefone?.toString(), dadosRequest.celular?.toString(), telefone)) {
          const encriptado = await encrypt(cpfCnpj)
          return navigate(`/nps/${encriptado}`);
        // } else {
        //   return Swal.fire({
        //     icon: "info",
        //     title: "Atenção",
        //     text: "Verifique o telefone/celular informado",
        //   });
        // }
      } else {
        if (!senha) {
          return Swal.fire({
            icon: "error",
            title: "Atenção",
            text: "Preencha corretamente o campo de senha para efetuar o login.",
          });
        }
        if (dadosRequest.senha_associacao?.toString() == senha) {
          const encriptado = await encrypt(cpfCnpj)
          navigate(`/npscnpj/${encriptado}`);
        } else {
          return Swal.fire({
            icon: "info",
            title: "Atenção",
            text: "Verifique a senha informada",
          });
        }
      }
    }
  };

  function onRecaptchaChange(value) {
    return setRecaptchaValue(value);
  }

  return (
    <>
      {isLoading && <Loading />}
      <div className="loginModalBackground">
        <div className="loginModalContainer">
          <div className="loginModalTitleCLoseBtn">
            <button onClick={() => closeModal(false)}>X</button>
          </div>
          <div className="loginModalContentBody">
            <label className="col-8 text-center">Informe seu CPF ou CNPJ.</label>
            <input
              className="input-login"
              type="text"
              value={cpfCnpj}
              onChange={handleChangeCpfCnpj}
              placeholder="Informe seu CPF/CNPJ"
            />
            <ReCAPTCHA
              sitekey="6Lf1UfYpAAAAALif4SceIRzsz9x5dYv1nuHa9pyU"
              onChange={onRecaptchaChange}
            />
            {/* {showTelefone && (
              <>
                <label className="col-8 text-center">Informe os 4 últimos dígitos de seu número telefone ou celular.</label>
                <input
                  className="input-login"
                  type="text"
                  minlength="4" maxlength="4"
                  value={telefone}
                  onChange={handleChangeTelefone}
                />
              </>
            )} */}
            {showSenha && (
              <>
                <label className="col-8 text-center">Informe a senha da sua associação</label>
                <input
                  className="input-login"
                  type="text"
                  value={senha}
                  onChange={handleChangeSenha}
                />
              </>
            )}
            <div className="loginModalContentButtons">
              <button onClick={handleConfirm} id="botaoLogin">Validar CPF/CNPJ</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
