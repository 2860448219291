import { React, useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput
} from 'mdb-react-ui-kit';
import { Button } from 'react-bootstrap';
import "../App.css";
import axios from '../services/axios';
import Swal from 'sweetalert2';
import routes from '../routes';
import Loading from '../Components/Loading';
import { useNavigate } from "react-router-dom";
import TradingV from "../Components/Trading";
import Toast from 'react-bootstrap/Toast';


function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("")
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [codigo, setCodigo] = useState();
  const [timer, setTimer] = useState(0);
  const [validado, setValidado] = useState(false);
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmaSenha, setconfirmaSenha] = useState('');
  const [userId, setUserId] = useState('');



  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  function setCodigoValidacao(e) {
    setOtp(e)
  }

  const handleSendCod = async () => {
    try {
      if (!email) {
        return Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Verifique o E-mail informado",
          showConfirmButton: false,
          timer: 1500
        });
      }

      setIsLoading(true);

      const response = await axios.get(routes.findUsuarioByEmail + email);

      if(response.data.userId === -1){
        return Swal.fire({
          icon: "warning",
          title: "Usuário não encontrado",
          text: "Informe um Email válido!",
        })
      }

      setUserId(response.data.userId)

      setTimer(60);

      const obj = {
        email, type: "redefinirsenha"
      }

      const request = await axios.post(routes.sendMail, obj, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return setCodigo(request.data.numero);
    } catch (error) {
      setIsLoading(false);
      console.log("TCL: handleLogin -> error", error)
      setApiError(error?.response?.data)
    } finally {
      setIsLoading(false);
    }
  };

  function handleValidateCode() {
    try {
      if (!otp) {
        return Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Codigo de validação invalido!",
          showConfirmButton: false,
          timer: 1500
        });
      }
      return setValidado(otp == codigo)
    } catch (error) {

    } finally {
      setIsLoading(false)
    }
  }

  async function handleNewPassword() {
    try {
      if (!novaSenha || !confirmaSenha) {
        return Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "Preencha todos os campos!",
          showConfirmButton: false,
          timer: 1500
        });
      }
      if(novaSenha != confirmaSenha){
        return Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: "As senhas devem ser iguais!",
          showConfirmButton: false,
          timer: 1500
        });
      }

      const obj = {email, senha: novaSenha, userId}

      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Ao confirmar a senha do usuário \n" + email + "\n será alterada",
        showConfirmButton: true,
        showDenyButton: true,
        denyButtonText: "Não",
        confirmButtonText: "Sim",
        confirmButtonColor: "#28a745"
      }).then(async(result) => {
        if (result.isDenied) {
          return
        }
        setIsLoading(true)
        const response = await axios.post(routes.changePassword, obj)

        if(response.data.status){
          Swal.fire({
            icon: "success",
            title: "Atenção",
            text: response.data.mensagem,
            showConfirmButton: true,
            confirmButtonText: "OK",
            confirmButtonColor: "#28a745"
          });
          return navigate("/login");
        }
        else{
          return Swal.fire({
            icon: "warning",
            title: "Atenção",
            text: response.data.mensagem,
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
        }
      });
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={{ backgroundColor: '#3c3c3c' }} className="App">
      {isLoading && <Loading />}
      <div style={{ display: "none" }}> <TradingV />  </div>
      <MDBContainer fluid style={{ height: '370px' }}>
        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
          <MDBCol col='12'>
            <MDBCard className='bg-dark text-white mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px', marginTop: "6rem" }}>
              <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                <span className="fw-bold mb-2 text-uppercase">Redefina sua senha</span>
                {!validado ? (
                  <>
                    <p className="text-white-50 mb-3">Informe seu E-mail</p>
                    <MDBInput
                      wrapperClass='mb-2 w-100'
                      labelClass='text-white'
                      label='E-mail'
                      size="lg"
                      type='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {codigo && (
                      <div>
                        <OtpInput
                          value={otp}
                          onChange={setCodigoValidacao}
                          numInputs={4}
                          inputType="number"
                          renderSeparator={<span style={{ margin: '0 3px' }}> </span>}
                          renderInput={(props) => <input {...props} style={{ width: '50px', textAlign: 'center', borderRadius: '10px', padding: '5px 10px', outline: 'none' }} />}
                        />
                      </div>
                    )}
                    {email && (
                      <>
                        {timer == 0 ? (
                          <Button className='col-10 my-3' color='secundary' size='lg' onClick={handleSendCod}>
                            Receber código
                          </Button>
                        ) : (
                          <Button className='col-10 my-3' color='secundary' size='lg'>
                            Reenvie o código de recuperação em {timer}s
                          </Button>
                        )}
                      </>
                    )}
                    {otp.length == '4' && (
                      <>
                        <Button className='col-10 my-3' color='secundary' size='lg' onClick={handleValidateCode}>
                          Validar código
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <MDBInput
                      wrapperClass='mb-2 w-100'
                      labelClass='text-white'
                      label='Digite sua nova senha'
                      size="lg"
                      type='password'
                      value={novaSenha}
                      onChange={(e) => setNovaSenha(e.target.value)}
                    />
                    <MDBInput
                      wrapperClass='mb-2 w-100'
                      labelClass='text-white'
                      label='Confirme sua nova senha'
                      size="lg"
                      type='password'
                      value={confirmaSenha}
                      onChange={(e) => setconfirmaSenha(e.target.value)}
                    />
                    <Button className='col-10 my-3' color='success' size='lg' onClick={handleNewPassword}>
                          Confirmar nova senha
                    </Button>
                  </>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

    </div>
  );
}

export default Login;