import axios from '../services/axios'
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import routes from '../routes';
import { formatDate, formatPhone, formatCPF, formatCNPJ, formatMoney } from '../functions/formater';
import Swal from 'sweetalert2';
import Loading from './Loading';


const CustomModal = ({ visible, onClose, produto, banco = false }) => {
  const [selectedOption, setSelectedOption] = useState(banco ? produto.statusBanco : produto.statusEmpresa);
  const [isLoading, setLoading] = useState(false);
  const [valorParcela, setValorParcela] = useState(produto.valor); // Novo estado

  if (!visible) {
    return null;
  }

  async function onSave() {
    setLoading(true);

    const dados = {
      Id: produto.Id,
      nome: produto.nome,
      telefone: produto.celular,
      cpf: produto.cpf,
      email: produto.email,
      valor: valorParcela,
      type: "aprovacaoconsignado",
      ...(banco ? { statusEmpresa: selectedOption } : { statusBanco: selectedOption }),
    };

    try {
      await axios.post(routes.saveChangeStatusEmpresa, dados);
      setLoading(false);
      onClose();
      Swal.fire({
        icon: "success",
        title: "Sucesso",
        text: "Status do consignado alterado.",
        showConfirmButton: false,
        timer: 1000,
      });
    } catch (error) {
      setLoading(false);
      console.error("Erro ao salvar:", error);
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Não foi possível alterar o status.",
      });
    }
  }

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleValorChange = (event) => {
    setValorParcela(event.target.value);
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <div className="custom-modal-overlay">
          <div className="custom-modal-content">
            <div className="custom-modal-upper-content">
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control type="text" disabled value={produto.nome} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Documento</Form.Label>
                  <Form.Control type="text" disabled value={formatCPF(produto.cpf)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Contato</Form.Label>
                  {produto.telefone || produto.celular ? (
                    <Form.Control type="text" disabled value={formatPhone(produto.telefone || produto.celular)} />
                  ) : (
                    <Form.Control type="text" disabled />
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Data Solicitação</Form.Label>
                  <Form.Control type="text" disabled value={formatDate(produto.CreatedAt)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Valor máx. parcela</Form.Label>
                  <Form.Control
                    type="text"
                    value={formatMoney(valorParcela)} // Vincula ao estado
                    onChange={handleValorChange} // Atualiza o estado
                  />
                </Form.Group>
              </Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  className="form-select form-select-lg mb-3"
                  aria-label=".form-select-lg example"
                  value={selectedOption}
                  onChange={handleChange}
                >
                  <option value="analise">Análise</option>
                  <option value="aprovado">Aprovado</option>
                  <option value="recusado">Recusado</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="custom-lower-modal-content">
              <button onClick={onSave} className="custom-save-button">
                Salvar
              </button>
              <button onClick={onClose} className="custom-close-button">
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomModal;