import axios from 'axios';
const API_URL = 'https://api.fazcredito.com.br';
// const API_URL = 'http://localhost:3001';

const instance = axios.create({
    baseURL: API_URL, 
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

export default instance;
