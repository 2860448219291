import React, { useState } from 'react';
import axios from '../services/axios';
import routes from '../routes';
import { IoSearchSharp } from "react-icons/io5";
import Swal from 'sweetalert2';
import CurrencyInput from 'react-currency-input-field';
import Loading from './Loading';
import encrypt from '../functions/Encrypt';
import { cpf } from 'cpf-cnpj-validator';

const initialFormState = {
  cpf: '',
  nome: '',
  email: '',
  celular: '',
  nomeAssociacao: '',
  operacao: 'credito',
  valor: 0.00,
  parcelas: 1,
  produto: 'Consignado privado',
  data_inicio: '',
  data_fim: ''
};

const FormBacklog = () => {
  const [desabilitado, setDesabilitado] = useState(false);
  const [cliente, setCliente] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(initialFormState);
  const [errorData, setErrorData] = useState(false);
  const [dateError, setDateError] = useState('');
  const [newAssociado, setNewAssociado] = useState(false);


  const valuesProdutos = [
    { value: "consignadoPrivado", label: "Consignado privado", tipo: 'C' },
    { value: "consignadoPublico", label: "Consignado público", tipo: 'C' },
    { value: "refinVeiculos", label: "Refinanciamento de veículos", tipo: 'I' },
    { value: "creditoDireto", label: "Crédito direto", tipo: 'C' },
    { value: "investimento", label: "Investimento", tipo: 'I' },
    { value: "consorcio", label: "Consórcio", tipo: 'I' },
    { value: "planejamentoFinanceiro", label: "Planejamento Financeiro", tipo: 'I' },
    { value: "cartaoCredito", label: "Cartão de crédito", tipo: 'I' },
    { value: "pesquisas", label: "Pesquisas NPS & CSAT", tipo: 'C' }
  ];

  const [formData, setFormData] = useState({
    cpf: '',
    nome: '',
    celular: '',
    email: '',
    nomeAssociacao: '',
    operacao: 'credito',
    valor: 0.00,
    parcelas: 1,
    produto: 'Consignado privado',
    data_inicio: '',
    data_fim: '',
    banco: ''
  });

  const updateFormData = (newData) => {
    setFormData(prevState => ({
      ...prevState,
      ...newData
    }));
  };

  const handleCurrencyChange = (value, name) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChange = (e) => {
    // Se o nome do campo for data_inicio ou data_fim, verificar se a data é válida
    if (e.target.name === 'data_inicio' || e.target.name === 'data_fim') {
      // Verificar se a data está no formato correto (yyyy-mm-dd)
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(e.target.value)) {
        setErrorData(true); // Definir o estado de erro como true
      } else {
        setErrorData(false); // Resetar o estado de erro se a data estiver no formato correto
      }
    }

    if (e.target.name == "operacao")
      changeOperationValueOnClick(e.target.value)


    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    // Verificar se a data_fim é menor que a data_inicio
    if (e.target.name === 'data_inicio' || e.target.name === 'data_fim') {
      const dataInicio = e.target.name === 'data_inicio' ? e.target.value : formData.data_inicio;
      const dataFim = e.target.name === 'data_fim' ? e.target.value : formData.data_fim;

      if (dataInicio && dataFim && new Date(dataFim) < new Date(dataInicio)) {
        setDateError('A data de fim não pode ser anterior à data de início.');
      } else {
        setDateError('');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dadosValidados = validadorDados();
    if(dadosValidados)
      return Swal.fire({
        icon: "error",
        title: "Atenção!",
        text: "Preencha os seguintes campos para prosseguir: " + dadosValidados,
        showConfirmButton: true,
      });


    // Verificar se há erro nas datas antes de enviar o formulário
    if (errorData || dateError) {
      return Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Por favor, corrija os erros no formulário antes de enviar.",
        showConfirmButton: false,
        timer: 1200
      });
    }

    const obj = {
      ...formData
    };

    try {
      setEmCarregamento(true);

      const { nome, cpf, email, celular, produto, data_inicio, data_fim } = formData;
      let saveNewAssociado = null
      if(newAssociado){
        const objAssociado = {
          nome, cpf, email, celular
        }
        saveNewAssociado = await axios.post(routes.saveAssociado, objAssociado, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      }

      const response = await axios.post(routes.saveProduto, obj, {
        headers: {
          'Content-Type': 'application/json'
        }
      });


      const dados = {
        nome,
        produto,
        data_inicio,
        data_fim,
        email,
        type: "vendaProduto"
      };

      await axios.post(routes.sendMail, dados);

      if (response.status === 200) {
        setFormData(initialFormState);
        setCliente(false);
        return Swal.fire({
          icon: "success",
          title: "Produto salvo",
          text: "O produto foi salvo com sucesso",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        return Swal.fire({
          icon: "error",
          title: "Atenção!",
          text: "O produto não foi salvo",
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      return Swal.fire({
        icon: "error",
        title: "Erro!",
        text: "Ocorreu um erro ao tentar salvar o produto. Por favor, tente novamente.",
        showConfirmButton: true,
        timer: 3000
      });
    } finally {
      setEmCarregamento(false);
    }
  };

  function validadorDados(){
    const camposVazios = [];

    if (formData.cpf.trim() === '') camposVazios.push('CPF');
    if (formData.nome.trim() === '') camposVazios.push('Nome');
    if (formData.celular <= 0) camposVazios.push('Celular');
    if (formData.email.trim() === '') camposVazios.push('Email');
    if (formData.nomeAssociacao.trim() === '') camposVazios.push('Nome da Associação');
    if (formData.valor <= 0) camposVazios.push('Valor');
    if (formData.parcelas < 1) camposVazios.push('Parcelas');
    if (formData.produto.trim() === '') camposVazios.push('Produto');
    if (formData.data_inicio.trim() === '') camposVazios.push('Data de Início');
    if (formData.data_fim.trim() === '') camposVazios.push('Data de Fim');
    if (formData.banco.trim() === '') camposVazios.push('Banco');

    return camposVazios.join(', ')
    
  }

  document.addEventListener('keydown', function (event) {
    if (event.key === 'Enter' && event.target.tagName === 'INPUT' && event.target.type !== 'submit') {
      event.preventDefault(); // Impede o submit do formulário
      const button = document.querySelector('.button-busca-associado');
      if (button && !button.disabled) {
        button.click(); // Simula o clique no botão
      }
    }
  });

  const findAssociado = async () => {
    try {
      if (cliente) {
        await Swal.fire({
          icon: "info",
          title: "Atenção!",
          html: `Existe uma inclução em processo, uma nova busca resultará na perda dos dados não salvos! <p> Deseja continuar?`,
          showCancelButton: true,
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar"
        }).then(async (result) => {
          if (result.isDismissed) {
            return;
          }
        });
      }

      const cpfValidacao = formData.cpf;
      if (!cpf.isValid(cpfValidacao))
        return Swal.fire({ icon: "info", title: "Atenção!!", text: "Informe um CPF válido para realizar a busca dos dados do associado" });

      setFormData(initialFormState);
      setEmCarregamento(true)
      const encriptado = await encrypt(cpfValidacao)
      const response = await axios.get(routes.authentication + encriptado);

      if (response && response.data) {
        updateFormData(response.data);
        setCliente(true);
      } else
        Swal.fire({
          icon: "info",
          title: "Atenção!!",
          text: "Nenhum associado localizado para esse CPF",
          confirmButtonText: "Nova busca",
          showDenyButton: true,
          denyButtonText: "Cadastrar novo associado",
          denyButtonColor: "#22aa66",
        }).then((result) => {
          if (result.isDenied) {
            setNewAssociado(true)
            setCliente(true);
          } else if (result.isConfirmed) { return null; }
        })

      console.log('INFORMAÇÕES IMPORNTANTES NÃO VAZAR');

      
    } catch (err) {
      console.log(err);
    } finally {
      setEmCarregamento(false)
    }
  };

  function setEmCarregamento(status) {
    setDesabilitado(status);
    setIsLoading(status)
  }

  function changeOperationValueOnClick(value) {
    return formData.produto = (value === 'credito') ? 'Consignado privado' : 'Refinanciamento de veículos'
  }

  const filteredProdutos = valuesProdutos.filter(produto => {
    if (formData.operacao === 'credito') {
      return produto.tipo === 'C';
    } else if (formData.operacao === 'investimento') {
      return produto.tipo === 'I';
    }
    return false;
  });

  return (
    <div className="form-section">
      {isLoading && <Loading />}
      <form className="form-container col-lg-11 col-md-11 col-xs-12" onSubmit={handleSubmit}>
        {!cliente && (
          <>
            <div className="warning-message">
              Por favor informe um CPF, para realizar a busca do cliente.
            </div>
          </>
        )}
        <div className="input-button-group col-lg-12 col-md-12 col-xs-12">
          <label className='col-lg-1'>CPF:</label>
          <input
            className='col-lg-4 col-md-5 col-xs-9'
            type="number"
            name="cpf"
            required
            placeholder="Digite o CPF"
            value={formData.cpfCliente}
            onChange={handleChange}
          />
          <button type="button" className="btn btn-primary button-busca-associado" onClick={findAssociado} disabled={desabilitado}><IoSearchSharp /></button>
        </div>

        {cliente && (
          <>
            <div className="form-line col-lg-11 col-md-11 col-xs-12">
              <label>Associado:</label>
              <input
                className='col-lg-11 col-md-11 col-xs-11'
                type="text"
                style={{ marginLeft: '10px' }}
                name="associado"
                placeholder=""
                value={formData.nome}
                onChange={handleChange}
              />

            </div>
            <div className="form-line col-lg-12 col-md-12 col-xs-12">

              <label>Celular:</label>
              <input
                type="number"
                name="celular"
                className='col-lg-4 col-md-4 col-xs-4'
                style={{ marginLeft: '34px' }}
                placeholder=""
                value={formData.celular}
                onChange={handleChange}
              />
              <label>E-mail:</label>
              <input
                type="email"
                name="email"
                className='col-lg-5 col-md-5 col-xs-5'
                placeholder=""
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-line col-lg-11 col-md-11 col-xs-12">
              <label>Associação:</label>
              <input
                className='col-lg-11 col-md-11 col-xs-12'
                type="text"
                name="nomeAssociacao"
                required
                placeholder=""
                value={formData.nomeAssociacao}
                onChange={handleChange}
              />
            </div>
            {/* 
              Eugenio vai verificar a necessidade de salvar apenas o user ou salvar tudo
            <div className="form-line col-lg-11 col-md-11 col-xs-12">
              <button className="btn-formback" onClick={salvarAssociado()}>Salvar apenas associado</button>
            </div> */}
            <div className="form-line col-lg-12 col-md-12 col-xs-12">
              <label>Registrar operação:</label>
              <input
                type="radio"
                name="operacao"
                value="credito"
                checked={formData.operacao === 'credito'}
                onChange={handleChange}
              /> Crédito
              <input
                type="radio"
                name="operacao"
                value="investimento"
                checked={formData.operacao === 'investimento'}
                onChange={handleChange}
              /> Investimento
            </div>

            <div className="form-line col-lg-11 col-md-11 col-xs-12">
              <label>Valor R$:</label>
              <CurrencyInput
                name="valor"
                className='col-lg-4 col-md-4 col-xs-4'
                placeholder="Digite o valor"
                value={formData.valor}
                decimalsLimit={2}
                onValueChange={(value, name) => handleCurrencyChange(value, name)}
              />
              <label>Nr parcelas:</label>
              <input
                type="number"
                className='col-lg-4 col-md-4 col-xs-4'
                name="parcelas"
                required
                placeholder="Digite o número de parcelas"
                value={formData.parcelas}
                onChange={handleChange}
              />
            </div>
            <div className="form-line col-lg-11 col-md-11 col-xs-12">
              <label>Banco:</label>
              <input
                type="text"
                className='col-lg-4 col-md-4 col-xs-12'
                style={{ marginLeft: '18px' }}
                name="banco"
                required
                placeholder="Digite o banco"
                value={formData.banco}
                onChange={handleChange}
              />
              <label>Produto:</label>
              <select
                name="produto"
                style={{ marginLeft: '31px' }}
                className='col-lg-4 col-md-4 col-xs-12'
                required
                value={formData.produto}
                onChange={handleChange}
              >
                {filteredProdutos.map((data) => (
                  <option key={data.value} value={data.label} prop={data.tipo}>{data.label}</option>
                ))}
              </select>
            </div>
            <div className="form-line col-lg-11 col-md-11 col-xs-12">
              <label>Início:</label>
              <input
                type="date"
                style={{ marginLeft: '25px' }}
                className="col-lg-4 col-md-4 col-xs-12"
                name="data_inicio"
                required
                value={formData.data_inicio}
                onChange={handleChange}
              />
              <label>Fim:</label>
              <input
                type="date"
                style={{ marginLeft: '69px' }}
                className="col-lg-4 col-md-4 col-xs-12"
                name="data_fim"
                required
                value={formData.data_fim}
                onChange={handleChange}
              />
            </div>
            {errorData && (
              <div className="error-message">
                Por favor, insira uma data no formato correto (yyyy-mm-dd).
              </div>
            )}
            {dateError && (
              <div className="error-message" style={{ color: 'red' }}>
                {dateError}
              </div>
            )}
            <button className="btn-formback" type="submit">Salvar</button>
          </>
        )}
      </form>
    </div>
  );
};

export default FormBacklog;
