import { React, useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput
}from 'mdb-react-ui-kit';
import { Button } from 'react-bootstrap';
import "../App.css";
import axios from '../services/axios';
import Swal from 'sweetalert2';
import routes from '../routes';
import Loading from '../Components/Loading';
import { useNavigate } from "react-router-dom";
import TradingV from "../Components/Trading";
import Toast from 'react-bootstrap/Toast';

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState("")


  const handleLogin = async () => {
    try {
      if(!username || !password){
        return Swal.fire({
          icon: "warning",
          title: "Login incorreto",
          text: "Verifique o usuário e senha informados",
          showConfirmButton: false,
          timer: 1500
        });
      }

      setIsLoading(true);

      const obj = {
        username: username,
        password: password
      }

      const request = await axios.post(routes.login, obj, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      localStorage.setItem("token", request.data.token)
      localStorage.setItem("nome", request.data.nome)
      localStorage.setItem("telefone", request.data.telefone)
      localStorage.setItem("documento", request.data.documento)
      localStorage.setItem("cnpjAssociacao", request.data.cnpjAssociacao)

      if(request.data.isUserFazCredito === 1)
        return navigate("/backoffice")
      else
        return navigate("/bp.backoffice")

    } catch (error) {
			console.log("TCL: handleLogin -> error", error)
      setApiError(error?.response?.data)
    }finally{
      setIsLoading(false);
    }
  };

  return (
    <div style={{ backgroundColor: '#3c3c3c' }} className="App">
      {isLoading && <Loading />}
      <div style={{display:"none"}}> <TradingV/>  </div>
      <MDBContainer fluid style={{ height: '370px' }}>
        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
          <MDBCol col='12'>
            <MDBCard className='bg-dark text-white mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px', marginTop: "6rem" }}>
              <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                <img src="/static/media/Logotipo.7d96ebe0fdbe8ea3688e.png" alt="Logo" style={{ height: '90px' }} />
                <span className="fw-bold mb-2 text-uppercase">Login</span>
                <p className="text-white-50 mb-3">Informe usuário e senha</p>
                <MDBInput 
                  wrapperClass='mb-2 w-100' 
                  labelClass='text-white' 
                  label='Usuário' 
                  size="lg" 
                  type='email' 
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)} 
                />
                <MDBInput 
                  wrapperClass='mb-3 w-100' 
                  labelClass='text-white' 
                  label='Senha' 
                  size="lg" 
                  type='password' 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                />
                <div>
                  <a href="/redefinirsenha">Esqueci minha senha</a>
                </div>
                {apiError &&(
                  <Toast bg={"danger"}>
                    <Toast.Body> {apiError} </Toast.Body>
                  </Toast>
                )}
                <Button className='col-10 my-3' color='secundary' size='lg' onClick={handleLogin}>
                  Login
                </Button>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

    </div>
  );
}

export default Login;